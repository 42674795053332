import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useMedia } from 'react-use';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { LOCAL_STORAGE } from '../../../../types/localStorage';
import Prices from './components/Prices/Prices';
import PlanPrice from './components/PlanPrice/PlanPrice';
import Method from './components/Method/Method';
import { useDepositPricesQuery } from './query';
import { DepositPricesModel, StoreMethodsModel } from './types';
import styles from './DepositModal.module.scss';
import { isDepositModal, isPlanSelectModel, useStoreActions, useStoreState } from '@store';
import { abortPayment, buyStreamMovies, savedCardCharge, sendPaymentData } from '@http/endpoints/payments';
import Spinner from '@common/spinner/Spinner';
import Sofort from '@images/payment-icons/method-draw-image.svg';
import Paypal from '@images/payment-icons/paypal-paysafe.svg';
import Visa from '@images/payment-icons/visa-mastercard.svg';
import { DiscreetPayment } from 'shared/discreet-payment';
import { RippleButton } from '@common/ui-components/buttons/RippleButton';
import { getLocalStorageObj } from '@helpers/getLocalStorage';

const url = window.location.protocol + '//' + window.location.host.split(':')[0];

type Props = {
  show: boolean;
  hide: () => void;
};
const DepositModal: FC<Props> = ({ show, hide }) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { setShowPhoneVerify, setSelectedPlan } = useStoreActions((actions) => actions);
  const { updateUser } = useStoreActions((state) => state.user);
  const { setDepositModalData, setRedirectURL } = useStoreActions((actions) => actions);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { selectedPlan, depositModalData, redirectURL } = useStoreState((state) => state);
  const { t } = useTranslation();
  const [method, setMethod] = useState<string>('card');
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [pricesData, setPricesData] = useState<DepositPricesModel[]>([]);
  const [storedMethods, setStoredMethods] = useState<StoreMethodsModel[]>([]);
  const [price, setPrice] = useState<number | null>(null);
  const [fromMembership, setFromMembership] = useState({});
  const [prevUsedCard, setPrevUsedCard] = useState<number | null>(null);
  const [checkPrice, setCheckPrice] = useState<number | null>(null);
  const [toRefetch, setToRefetch] = useState(null);
  const location = useLocation();
  const [step, setStep] = useState(1);
  const { addToast } = useToasts();
  const isDesktop = useMedia('(min-width: 768px)');

  const { depositPrices, balance, stored_methods } = useDepositPricesQuery(show);

  useEffect(() => {
    setDisableNextBtn(false);
  }, [method]);

  useEffect(() => {

    return () => {
      setShowSpinner(false);
      handleClose();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedPlan).length) {
      setFromMembership(selectedPlan);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (!isAuthenticated) hide();
  }, [isAuthenticated, hide]);

  useEffect(() => {
    const data = getLocalStorageObj(LOCAL_STORAGE.DEPOSIT_MODAL_DATA);
    if(data && data?.depositFormData?.price && data.depositFormData?.method) {
      const _dataForm = data?.depositFormData;
      setMethod(_dataForm.method);
      setDepositModalData(data.depositModalData);
      setSelectedPlan(data.selectedPlan);
      setRedirectURL(data.redirectURL);
      setPrice(Object.keys(data.selectedPlan).length && isPlanSelectModel(data.selectedPlan) ? data.selectedPlan.data.id : _dataForm.price);
      localStorage.removeItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN);
      localStorage.removeItem(LOCAL_STORAGE.DEPOSIT_MODAL_DATA);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(depositModalData).length && isDepositModal(depositModalData)) {
      if (depositModalData.price) setCheckPrice(depositModalData.price);
      if (depositModalData.refetchData) setToRefetch(depositModalData.refetchData);
    }
  }, [depositModalData]);

  useEffect(() => {
    const data = getLocalStorageObj(LOCAL_STORAGE.DEPOSIT_MODAL_DATA);
    if(!data) {
      if(localStorage.getItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN)) {
        localStorage.removeItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN);
      }
    }
  }, []);

  useEffect(() => {
    const prices = depositPrices?.length ? depositPrices : [];
    const methods = stored_methods?.length ? stored_methods : [];

    setPricesData(prices);
    setStoredMethods(methods);
  }, [balance, depositPrices, stored_methods]);

  /**
   * Check if there are stored previous used cards and if they are, select that method first
   */
  useEffect(() => {
    if (storedMethods?.length) {
      setPrevUsedCard(storedMethods[0].id);
      handleMethodSelect('savedCard');
    }
  }, [storedMethods, selectedPlan]);

  /**
   * Handle action after modal has been sent to BE and URLs generated
   */
  const handleNext = () => {
    setDisableNextBtn(true);
    const locationPath = method === 'card' ?  `${url}/success`  : redirectURL ? `${url}${redirectURL}` : `${url}${location.pathname}`;
    localStorage.setItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN, `${new Date().getTime()}`);
    localStorage.setItem(LOCAL_STORAGE.DEPOSIT_MODAL_DATA, JSON.stringify({
      redirectURL: redirectURL || `${location.pathname}`,
      selectedPlan,
      depositModalData,
      depositFormData: {
        method,
        method_id: prevUsedCard,
        price: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
        amount: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
        redirect_url: locationPath,
        filter: Object.keys(selectedPlan).length ? 'svod' : 'deposit',
        fromSavedCard: true,
        fromMembership: fromMembership,
        isSpecialOffer: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) && selectedPlan?.data?.is_special_offer
      },
    }));
    if (method === 'sepaDirectDebit') {
      setShowPhoneVerify({
        method,
        price: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
        url: locationPath,
        filter: Object.keys(selectedPlan).length ? 'svod' : 'deposit',
        isSpecialOffer: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) && selectedPlan?.data?.is_special_offer
      });
      handleClose();
    } else if (method === 'card' || method === 'sofort') {
      openHPModal(locationPath);
    } else if (method === 'paypal') {
      openHPModal(locationPath);
    } else if (method === 'savedCard') {
      const filtered = storedMethods.filter(
        (item) => Number(item.id) === Number(prevUsedCard) && item.type_detail === 'Sepa'
      );

      if (filtered?.length) {
        setShowPhoneVerify({
          method,
          method_id: prevUsedCard,
          price: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
          amount: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
          redirect_url: locationPath,
          filter: Object.keys(selectedPlan).length ? 'svod' : 'deposit',
          fromSavedCard: true,
          fromMembership: fromMembership,
          isSpecialOffer: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) && selectedPlan?.data?.is_special_offer
        });
        handleClose();
      } else {
        handleSavedCardCharge(locationPath);
      }
    }
    setRedirectURL(null);
  };

  const handleSendHP = (id: number) => {
    const lang = localStorage.getItem('user_lang');
    const locale = lang ? lang : 'en';
    const checkout = new window.checkout(id, {
      locale,
    });
    setShowSpinner(false);
    // Initialize the payment page
    checkout
      .init()
      .then(function () {
        // If successfull, open the dialog
        setShowSpinner(false);
        checkout.open();
        // Add your event listeners for abort or success events
        checkout.abort(function () {
          setShowSpinner(false);
          deletePaymentId();
        });
        checkout.success(function () {
          setDisableNextBtn(false);
          updateUser();
          if (toRefetch) {
            queryClient.invalidateQueries(toRefetch);
          }
          window.location.reload();
        });
        checkout.error(function (err: Error) {
          setShowSpinner(false);
          deletePaymentId();
          setDisableNextBtn(false);
          // eslint-disable-next-line no-console
          console.error(err);
        });
      })
      .catch(function (err: Error) {
        // Handle error when initialize the payment page
        setShowSpinner(false);
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const handleSavedCardCharge = async (locationPath: string) => {
    setShowSpinner(true);
    const values = {
      method_id: prevUsedCard,
      amount: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
      redirect_url: locationPath,
      filter: Object.keys(selectedPlan).length ? 'svod' : 'deposit'
    };
    const { data, error } = await savedCardCharge(values);
    if (error) {
      setShowSpinner(false);
      if (error?.response?.data?.message) {
        addToast(error.response.data.message, {
          appearance: 'warning',
        });
      } else {
        addToast('Error', {
          appearance: 'warning',
        });
      }
      if (error?.response?.status === 402) {
        if (error.response.data?.data?.payment_id) {
          handleSendHP(error.response.data.data.payment_id);
        } else if (error.response.data?.data?.redirect_url) {
          window.location.href = error.response.data.data.redirect_url;
        }
      }
    }
    if (data) {
      handleClose();
      setShowSpinner(false);

      localStorage.removeItem(LOCAL_STORAGE.DEPOSIT_MODAL_AUTO_OPEN);
      localStorage.removeItem(LOCAL_STORAGE.DEPOSIT_MODAL_DATA);

      if (toRefetch) {
        queryClient.invalidateQueries(toRefetch);
      }

      if (data?.data?.data) {
        addToast(data.data.message, { appearance: 'success' });
      } else if (data?.data?.message) {
        addToast(data.data.message, { appearance: 'success' });
      }
      const moviePurchaseInfo = localStorage.getItem('movie-purchase-info');
      if (moviePurchaseInfo) {
        handleBuyMovie(JSON.parse(moviePurchaseInfo));
      }
      if (Object.keys(fromMembership).length) {
        window.location.reload();
      }
      updateUser();
    }
  };

  const openHPModal = async (locationPath: string) => {
    setShowSpinner(true);

    const { data, error } = await sendPaymentData({
      amount: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? selectedPlan.data.id : price,
      payment_method: method,
      redirect_url: locationPath,
      filter: Object.keys(selectedPlan).length ? 'svod' : 'deposit',
      isSpecialOffer: Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) && selectedPlan?.data?.is_special_offer
    });

    if (error?.response?.data?.message) {
      addToast(error.response.data.message, {
        appearance: 'warning',
      });
      setDisableNextBtn(false);
      setShowSpinner(false);
      handleClose();
    }

    if (data) {
      const redirectUrl = data?.data?.data?.redirect_url;

      if (redirectUrl) {
        setShowSpinner(false);
        window.location.href = redirectUrl;
      } else {
        handleSendHP(data.data.data.payment_id);
        setDisableNextBtn(false);
        setShowSpinner(false);
        handleClose();
      }
    } else {
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleMethodSelect = (value: string) => {
    setMethod(value);
  };

  const handlePriceSelect = useCallback((value: string | number) => {
    setPrice(Number(value));
  }, []);

  const customStyles = {
    modal: {
      animation: `${show ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`,
      background: 'rgb(235, 235, 235)',
      borderRadius: '7px',
      width: 'auto',
      padding: '0',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  };

  function handleClose() {
    if (toRefetch) {
      queryClient.invalidateQueries(toRefetch);
    }
    setSelectedPlan({});
    setCheckPrice(null);
    setDepositModalData({});
    setPrevUsedCard(null);
    setToRefetch(null);
    setStoredMethods([]);
    hide();
    setRedirectURL(null);
  }

  const deletePaymentId = async () => {
    const { data } = await abortPayment();
    if (data) {
      updateUser();
    }
  };

  const handleBuyMovie = async (values: unknown) => {
    setShowSpinner(true);
    const { data, error } = await buyStreamMovies(values);
    if (error) {
      if (error.response?.data?.message) addToast(error.response.data.message, { appearance: 'error' });
      setShowSpinner(false);
    }
    if (data) {
      if (data.data?.status === 'success') {
        setShowSpinner(false);
        updateUser();
        addToast(data.data.message, { appearance: 'success' });
        localStorage.removeItem('movie-purchase-info');
        setTimeout(() => {
          window.window.location.reload();
        }, 1000);
        if (toRefetch) {
          queryClient.invalidateQueries(toRefetch);
        }
      }
    }
  };

  const renderSavedCardLayout = () => {
    return (
      <>
        {storedMethods?.length ? (
          <div
            onKeyDown={() => handleMethodSelect('savedCard')}
            role="button"
            tabIndex={0}
            onClick={() => handleMethodSelect('savedCard')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleMethodSelect('savedCard');
              }
            }}
            className={''}
          >
            <p className={styles.payment_method_title}>{t('PAYMENT METHOD')}</p>
            <div className={`${styles.saved_card_wrapper} ${method === 'savedCard' ? styles.method_saved_card : ''}`}>
              <select
                tabIndex={0}
                onChange={(e) => setPrevUsedCard(Number(e.target.value))}
                className={styles.select_input}
              >
                {storedMethods.map((item) => {
                  return (
                    <option className={styles.select_option} key={item.id} value={item.id}>{`${item.type_detail} ${t(
                      'ending with'
                    )} ${item.last_4}`}</option>
                  );
                })}
              </select>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderPaymentMethodLayout = () => {
    return (
      <>
        <div className={styles.method_container}>
          <p className={styles.method_title}>{storedMethods?.length ? t('OTHER METHODS') : t('PAYMENT METHOD')}</p>
          <Method handleMethodSelect={handleMethodSelect} methodName={'card'} Icon={Visa} method={method} />
          {!Object.keys(selectedPlan).length ? (
            <Method handleMethodSelect={handleMethodSelect} methodName={'sofort'} method={method} Icon={Sofort} />
          ) : null}

          <Method handleMethodSelect={handleMethodSelect} methodName={'paypal'} method={method} Icon={Paypal} />
        </div>
      </>
    );
  };

  const renderPricePlanLayout = () => {
    return (
      <div className={styles.amount_container}>
        {Object.keys(selectedPlan).length && isPlanSelectModel(selectedPlan) ? (
          <PlanPrice
            data={selectedPlan.data}
            days={selectedPlan.days as number}
            priceOne={selectedPlan.priceOne as number}
            priceTotal={selectedPlan.priceTotal}
            months={selectedPlan.months as number}
          />
        ) : (
          <Prices
            handlePriceSelect={handlePriceSelect}
            data={pricesData}
            price={price as number}
            checkPrice={checkPrice as number}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Spinner show={showSpinner} />
      <Modal open={show} onClose={handleClose} styles={customStyles} center={true}>
        <div id="deposit-modal" className={styles.container}>
          <div className={styles.title_container}>
            <h2 className={styles.title}>{t('GET INSTANT ACCESS NOW')}!</h2>
          </div>

          <div className={styles.card_money_button_container}>
            {!isDesktop && step === 1 ? null : (
              <div className={styles.saved_card_container}>
                {renderSavedCardLayout()}
                <div className={styles.method_amount_container}>
                  {renderPaymentMethodLayout()}
                  {!isDesktop && step === 2 ? null : renderPricePlanLayout()}
                </div>
                <>
                  <div className={styles.next_back_container}>
                    {!isDesktop && (
                      <RippleButton className={`${styles.back_button}`} onClick={() => setStep(1)}>
                        {t('Back').toUpperCase()}
                      </RippleButton>
                    )}
                    <RippleButton
                      disabled={disableNextBtn}
                      className={`${styles.next_button} ${disableNextBtn ? styles.disabled_button : ''}`}
                      onClick={handleNext}
                    >
                      {t('Next').toUpperCase()}
                    </RippleButton>
                  </div>
                  <p className={styles.age_confirmation}>
                    {t("By clicking 'Next' you confirm you are aged 18 or older")}.
                  </p>
                </>
              </div>
            )}
            {!isDesktop && step === 1 && (
              <div className={styles.mobile_layout}>
                {renderPricePlanLayout()}
                <RippleButton
                  disabled={Object.keys(fromMembership).length <= 0 && !price ? true : false}
                  className={styles.next_button}
                  onClick={() => setStep(2)}
                >
                  {t('Next').toUpperCase()}
                </RippleButton>
              </div>
            )}
          </div>
          <DiscreetPayment />
        </div>
      </Modal>
    </>
  );
};

export default DepositModal;
